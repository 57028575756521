<template>
  <section>
    <!-- breadcrumb -->
    <div class="breadcrumb-top">
      <b-breadcrumb class="">
        <b-breadcrumb-item :to="{ name: 'dashboard' }" >
          <feather-icon
            icon="HomeIcon"
            size="16"
          />
        </b-breadcrumb-item>
        <b-breadcrumb-item>
          {{ $t('Report') }}
        </b-breadcrumb-item>
        <b-breadcrumb-item active >
          {{ $t('Status Report') }}
        </b-breadcrumb-item>
      </b-breadcrumb>
    </div>

    <!-- content -->
    <b-card no-body>
      <!-- <b-card-header>
        <b-card-title>Base Info</b-card-title>
      </b-card-header> -->
      <b-card-body class="ll-cbody">
        <validation-observer ref="rules">
        <b-row>
          <b-col md='3'>
            <b-form-group
              :label="$t('Order Status')"
              label-for="s"
            >
              <!-- <validation-provider
                #default="{ errors }"
                name="Report Type"
                rules="required"
                class="ll-formGroup"
              > -->
                <!-- <label class="ll-boldText">{{ $t('Order Status') }}</label> -->
                <v-select
                  :options="statusList"
                  label="codeDescription"
                  @input="selStatus"
                  v-model="status"
                  class="ll-ipt"
                ></v-select>
                <!-- <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider> -->
            </b-form-group>
          </b-col>
          <b-col md='3'>
            <b-form-group
              label=""
              label-for="s"
            >
              <validation-provider
                #default="{ errors }"
                name="Status Time Range"
                rules="required"
                class="ll-formGroup"
              >
                <label class="ll-boldText">{{ $t('Status Time Range') }}</label>
                <flat-pickr
                  v-model="time"
                  class="form-control ll-fpickr"
                  :config="dateConfig"
                  @input="selTime"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

        </b-row>
        <b-row>
          <b-col md='3'>
            <b-form-group
              :label="$t('Customer')"
              label-for="s"
            >
              <v-select
                :options="customerList"
                label="customer"
                multiple
                v-model="customerName"
                @input="getCustomer"
                >
                </v-select>
            </b-form-group>
          </b-col>
          <b-col md='3'>
            <b-form-group
              :label="$t('Tracking Number')"
              label-for="s"
            >
              <b-form-textarea
                v-model="tkIds"
                rows="2"
                trim
              />
            </b-form-group>
          </b-col>
          <b-col md='3'>
            <b-button
              variant="info"
              @click.prevent="validationForm"
              class="ll-btns"
            >
              <span class="text-nowrap">{{ $t('Generate') }}</span>
            </b-button>
          </b-col>
        </b-row>
        </validation-observer>
      </b-card-body>
    </b-card>
    <b-card no-body>
      <b-card-header>
        <b-card-title>{{ $t('Task List') }}</b-card-title>
      </b-card-header>
      <b-card-body >
        <b-table :items="items" :fields="fields"
          :borderless='false' :hover="true"
          :sticky-header="true"
          class="ll-table"
          :busy="isBusy"
        >
          <template #table-busy>
            <div class="text-center my-2">
              <b-spinner class="align-middle"></b-spinner>
            </div>
          </template>
          <template #cell(action)="scope">
            <b-button
              class="btn-icon"
              variant="flat-danger"
              @click="del(scope.item.id)"
            >
              <feather-icon icon="XIcon" />
            </b-button>
            <b-button
              class="btn-icon"
              variant="flat-info"
              :href="scope.item.output"
              :disabled="scope.item.output===null"
              :style="{'cursor': (scope.item.output===null) ? 'no-drop' : 'pointer'}"
            >
              <feather-icon icon="ArrowDownIcon" />
            </b-button>
            <b-button
              class="btn-icon"
              variant="flat-warning"
              @click="rePublish(scope.item.originalClassName)"
            >
              <feather-icon icon="RefreshCcwIcon" />
            </b-button>
          </template>
          <template #cell(progressNow)="scope">
            <b-progress
              key="info"
              :max="scope.item.progressMax"
              :variant="(scope.item.progressStatus==='success') ? 'success' : ((scope.item.progressStatus==='fail')?'danger':'info')"
              :class="(scope.item.progressStatus==='success') ? 'progress-bar-success' : ((scope.item.progressStatus==='fail')?'progress-bar-danger':'progress-bar-info')"
            >
              <b-progress-bar
                :value="scope.value"
                :variant="(scope.item.progressStatus==='success') ? 'success' : ((scope.item.progressStatus==='fail')?'danger':'info')"
                :label="`${((scope.item.progressNow/scope.item.progressMax) * 100).toFixed(2)}%`"
              />
            </b-progress>
          </template>
          <template #cell(input)="scope">
            <span>{{scope.item.taskName}}_{{(scope.item.createdAt)}}</span>
          </template>
        </b-table>
      </b-card-body>
    </b-card>
  </section>
</template>

<script>
import {
  BBreadcrumb, BBreadcrumbItem,
  BRow, BCol, BFormGroup, BFormInput, BButton,
  BCard, BCardBody, BCardHeader, BCardTitle,BFormTextarea,
  BTable, BProgress, BProgressBar, BSpinner,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import flatPickr from 'vue-flatpickr-component'
import '@core/scss/vue/libs/vue-flatpicker.scss'
import { debounce } from "@/libs/fun.js"
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'

export default {
  components: {
    BBreadcrumb,
    BBreadcrumbItem,
    BFormTextarea,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BCard,
    BCardBody,
    BCardHeader,
    BCardTitle,
    vSelect,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    flatPickr,
    BButton,
    BTable,
    BProgress,
    BProgressBar,
    ValidationProvider,
    ValidationObserver,
    BSpinner,
  },
  data() {
    return {
      customerList: [],
      required,
      type: '',
      statusList: [],
      status: '',
      time: '',
      dateConfig: {
        dateFormat: 'Y-m-d',
        mode: 'range',
        // defaultDate: [(new Date).setDate((new Date).getDate() - 7), new Date()],
        enableTime: false,
      },
      items: [],
      fields: [
        { key: 'input', label: 'Input Name' },
        { key: 'progressNow', label: 'Progress Now', class: 'll-progress' },
        { key: 'action', label: this.$t('Action') }
      ],
      info: {
        reportType: 'StatusCode',
        from: null,
        to: null,
        orderStatus: null,
        orgIds:null,
      },
      isBusy: true,
      customerName: null,
      accountNum: null,
      tkIds:null,
      customer:'',
    }
  },
  computed: {},
  watch: {
  },
  created() {
    this.getExportList()
  },
  mounted() {
    this.timer = setInterval(() => {
      setTimeout(() => {
        // setTimeout是自带清除定时器
        this.getExportList()
      }, 0)
    }, 5000)
    this.queryStatus()
    this.queryCustomer()
  },
  destroyed() {
    clearInterval(this.timer)
  },
  methods: {
    queryStatus() {
      this.$http.get('/masterData/findAllByDataType', { params: { data_type: 'reportOrderStatus'}}).then(res => {
        this.statusList = res.data.data
      })
    },
    // 获取客户名列表
    queryCustomer() {
      this.$http.get('csCansExt/getCustomerList').then(res => {
        this.customerList = res.data.data
      })
    },
    //获取客户名
    getCustomer(c) {
        const arr = []
        c.map(item => {
            arr.push(item.providerCode)
        })
        this.info.orgIds = arr
        // if(c){
        //     this.customerName = c.customer
        //     this.info.orgIds = c.providerCode
        // }else{
        //     this.customerName = null
        //     this.info.orgIds = null
        // }
        
    },
    selStatus(d) {
      // this.info.content = d.codeName
      if (d) {
        this.status = d.codeDescription
        this.info.orderStatus = d.codeName
      } else {
        this.status = null
        this.info.orderStatus = null
      }
    },
    selTime: debounce(function() {
      const dd = this.time.split('to ')
      if (dd[0] !== '') {
        this.info.from = (dd[1] !== undefined)? (dd[0] + '00:00:00') : (dd[0] + ' 00:00:00')
        this.info.to = ((dd[1] !== undefined)? dd[1] : dd[0])  + ' 23:59:59'
      } else {
        this.info.from = null
        this.info.to = null
      }
    },500),
    // 报表任务列表
    getExportList() {
      this.$http.get('/exportReport/getExportProcessStatus/StatusCode').then(res=>{
        this.items = res.data.data
        this.isBusy = false
      })
    },
    // 发布任务
    publish() {
      this.$http.post('/exportReport/publish', this.info).then(res=> {
          console.info(res,'res')
          if(res.data.code != -1){
              this.$toast({
                component: ToastificationContent,
                props: {
                    title: res.data.message,
                    icon: 'CheckIcon',
                    variant: 'success',
                },
              })
            this.getExportList()
          }
        
      })
    },
    validationForm() {
        if(this.tkIds){
            this.info.tkIds = this.tkIds.trim().split(/[(\r\n)\r\n]+/)
        }
      this.$refs.rules.validate().then(success => {
        if (success) {
          //  校验成功
          this.publish()
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Please check the data',
              icon: 'XIcon',
              variant: 'danger',
            },
          })
          return false
        }
        return true
      })
    },
    del(id) {
      this.box = ''
      this.$bvModal.msgBoxConfirm('Please confirm that you want to delete .', {
        title: 'Handle',
        size: 'sm',
        okVariant: 'primary',
        okTitle: 'Yes',
        cancelTitle: 'No',
        cancelVariant: 'outline-secondary',
        hideHeaderClose: false,
        centered: true,
      }).then(value => {
        if (value) {
          this.box = value
          this.$http.delete(`/exportReport/delete/${id}`).then(res=>{
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Delete Success',
                icon: 'CheckIcon',
                variant: 'success',
              },
            })
            this.getExportList()
          })
        }
      })
    },
    rePublish(input) {
        console.info(input,'input')
      const i = JSON.parse(input)
      const obj = {}
      obj.reportType = i.reportType
      obj.from = i.from.replace(/[A-Z]/g, ' ').trim()
      obj.to = i.to.replace(/[A-Z]/g, ' ').trim()
      this.$http.post('/exportReport/publish', obj).then(res=> {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: res.data.message,
            icon: 'CheckIcon',
            variant: 'success',
          },
        })
        this.getExportList()
      })
    },
  }
}
</script>

<style scoped>
.ll-btns{
  margin-top: 18px;
}
/* .ll-formGroup{
  display: flex;
  align-items: center;
  width: 100%;
}
.ll-formGroup .ll-boldText{
  width: 30%;
}
.ll-formGroup .ll-ipt{
  width: 70%;
} */
</style>
<style>
.table thead th, .table tfoot th {
  text-transform: capitalize;
}
.ll-progress{
  width: 50%;
  text-align: center;
}
.ll-progress .progress{
  height: 2rem;
}
.ll-tabled th{
  display: none;
}
</style>